<mg-manager-overlay-reports-subpage
  [loading]="false"
  [scheduleReportsButton]="false"
  [exportListButton]="false"
  [columnSettingsButton]="false">
  <!-- Header -->
  <ng-template #header>
    <ng-container
      *ngIf="media.isMobileView$ | async; else headerTemplate"
      [ngTemplateOutlet]="mobileHeaderTemplate">
    </ng-container>
    <ng-container> </ng-container>
  </ng-template>

  <!-- Main Content -->
  <ng-template #content>
    <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
  </ng-template>
</mg-manager-overlay-reports-subpage>

<!-- Static Header Template -->
<ng-template #staticHeaderTemplate>
  <div fxLayoutAlign="start center">
    <span class="header-title">
      {{ MESSAGES.PAGE_TITLE }}
    </span>
  </div>
</ng-template>

<!-- Date Picker Template -->
<ng-template #datePickerTemplate>
  <div class="filters-field full-width date-range">
    <mg-form-date-range [range]="range"></mg-form-date-range>
  </div>
</ng-template>

<!-- Name Filter Template -->
<ng-template #nameFilterTemplate>
  <div fxFlex.gt-sm="300px">
    <mg-form-select
      [placeholder]="MM_MESSAGES.NAME_INPUT_PLACEHOLDER"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      mingaSearch
      [multiple]="false"
      [searchable]="true"
      [isClearable]="true"
      [closeOnSelect]="true"
      [value]="(reportsFilters.filters$ | async)?.hash"
      [options]="[curMinga]"
      (selectionChanged)="onNameChange($event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Partner Filter Template -->
<ng-template #partnerFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Partner"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.partner"
      [closeOnSelect]="true"
      [multiple]="false"
      [searchable]="true"
      [isClearable]="true"
      [options]="reportsFilters.partnerOptions$ | async"
      (selectionChanged)="reportsFilters.setFilter(FILTERS.PARTNER, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Subscription Filter Template -->
<ng-template #subscriptionFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Subscription"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.subscription"
      [closeOnSelect]="false"
      [isClearable]="true"
      [multiple]="true"
      [searchable]="true"
      [options]="reportsFilters.subscriptionOptions$ | async"
      (selectionChanged)="
        reportsFilters.setFilter(FILTERS.SUBSCRIPTION, $event)
      ">
    </mg-form-select>
  </div>
</ng-template>

<!-- Module Filter Template -->
<ng-template #moduleFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Module"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.module"
      [closeOnSelect]="false"
      [isClearable]="true"
      [searchable]="true"
      [multiple]="true"
      [options]="reportsFilters.moduleOptions$ | async"
      (selectionChanged)="reportsFilters.setFilter(FILTERS.MODULE, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- District Filter Template -->
<ng-template #districtFilterTemplate>
  <div fxFlex.gt-sm="250px">
    <mg-form-select
      placeholder="District"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.district"
      [closeOnSelect]="false"
      [searchable]="true"
      [multiple]="false"
      [closeOnSelect]="true"
      [isClearable]="true"
      [options]="reportsFilters.districtOptions$ | async"
      (selectionChanged)="reportsFilters.setFilter(FILTERS.DISTRICT, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- State Filter Template -->
<ng-template #stateFilterTemplate>
  <div fxFlex.gt-sm="250px">
    <mg-form-select
      placeholder="State"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.state"
      [closeOnSelect]="false"
      [isClearable]="true"
      [searchable]="true"
      [multiple]="false"
      [closeOnSelect]="true"
      [options]="reportsFilters.stateOptions$ | async"
      (selectionChanged)="reportsFilters.setFilter(FILTERS.STATE, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Status Filter Template -->
<ng-template #statusFilterTemplate>
  <div fxFlex.gt-sm="200px">
    <mg-form-select
      placeholder="Status"
      [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
      [value]="(reportsFilters.filters$ | async)?.status"
      [closeOnSelect]="false"
      [multiple]="true"
      [isClearable]="true"
      [options]="reportsFilters.statusOptions$ | async"
      (selectionChanged)="reportsFilters.setFilter(FILTERS.STATUS, $event)">
    </mg-form-select>
  </div>
</ng-template>

<!-- Filter Buttons Template -->
<ng-template #filterButtonTemplate>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap="10px">
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [variant]="(media.isMobileView$ | async) ? 'filled' : 'outlined'"
        [size]="(media.isMobileView$ | async) ? 'medium' : 'small'"
        [responsive]="true"
        (pressed)="applyFilter()">
        {{ MESSAGES.BUTTON_APPLY_FILTERS }}
      </mg-btn>
    </div>
    <div
      fxFlex="grow"
      fxFlex.gt-sm="nogrow">
      <mg-btn
        [variant]="(media.isMobileView$ | async) ? 'outlined' : 'text'"
        [size]="(media.isMobileView$ | async) ? 'medium' : 'small'"
        [responsive]="true"
        (pressed)="clearFilters()">
        {{ MESSAGES.BUTTON_CLEAR_FILTERS }}
      </mg-btn>
    </div>
  </div>
</ng-template>

<!-- Stats / Metrics Template -->
<ng-template #statsTemplate>
  <div class="stats-container">
    <ng-container
      *ngIf="
        (reports.isLoadingTotals$ | async) === false;
        else loadingTilesTpl
      ">
      <div
        *ngFor="let tile of tileData$ | async"
        class="stats-tile-container">
        <mg-count-tile
          [count]="tile.total"
          [label]="tile.title"
          [color]="tile.color"
          [icon]="tile.icon"
          [isActive]="(reportsFilters.filters$ | async)?.type === tile.type"
          [fixedHeight]="true"
          [backgroundColor]="
            (layout.useLegacyLayout$ | async) ? 'grey' : 'white'
          "
          (tileClicked)="onTileClick(tile)">
        </mg-count-tile>
      </div>
    </ng-container>
    <ng-template #loadingTilesTpl>
      <div class="spinner-tiles">
        <mg-spinner></mg-spinner>
      </div>
    </ng-template>
  </div>
</ng-template>

<!-- Main Content Template -->
<ng-template #mainContentTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="start start">
    <!-- Statistics Tiles -->
    <div fxFlex="33">
      <ng-container *ngTemplateOutlet="statsTemplate"></ng-container>
    </div>
    <!-- Chart -->
    <div
      *ngIf="(reports.isLoadingGraph$ | async) === false; else loadingGraphTpl"
      fxFlex="66">
      <mg-bar-chart
        [data]="graphData$ | async"
        [minDate]="startDate$ | async"
        [maxDate]="endDate$ | async">
      </mg-bar-chart>
    </div>
    <ng-template #loadingGraphTpl>
      <div
        fxFlex="66"
        class="spinner-graph">
        <mg-spinner></mg-spinner>
      </div>
    </ng-template>
  </div>
</ng-template>

<!-- Desktop Header -->
<ng-template #headerTemplate>
  <div class="filters">
    <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>

    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="partnerFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="districtFilterTemplate"></ng-container>

    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="moduleFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="subscriptionFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="stateFilterTemplate"></ng-container>
    <ng-container
      *ngIf="isSuperAdmin"
      [ngTemplateOutlet]="statusFilterTemplate"></ng-container>

    <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
  </div>
</ng-template>

<!-- Mobile Header -->
<ng-template #mobileHeaderTemplate>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="8px">
    <ng-container *ngTemplateOutlet="datePickerTemplate"></ng-container>
    <mg-collapsible
      [collapseText]="MESSAGES.HIDE_FILTERS"
      [expandText]="MESSAGES.SHOW_FILTERS">
      <div class="filters">
        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="partnerFilterTemplate"></ng-container>
        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="districtFilterTemplate"></ng-container>

        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="moduleFilterTemplate"></ng-container>
        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="subscriptionFilterTemplate"></ng-container>
        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="stateFilterTemplate"></ng-container>
        <ng-container
          *ngIf="isSuperAdmin"
          [ngTemplateOutlet]="statusFilterTemplate"></ng-container>
      </div>
    </mg-collapsible>
    <ng-container *ngTemplateOutlet="filterButtonTemplate"></ng-container>
  </div>
</ng-template>
